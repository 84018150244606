import { combineReducers, Reducer } from "redux";
import { UPDATE_CURRENT_PATH } from "../actions/root.actions";
import { IRootStateType, IActionBase, IStateType } from "../models/root.interface";
import productsReducer from "./products.reducer";
import offersReducer from "./offers.reducer";
import cuponsReducer from "./cupons.reducer";
import subjectsReducer from "./subjects.reducer";
import subjectResalesReducer from "./subjectResales.reducer";
import questionsReducer from "./questions.reducer";
import questionResalesReducer from "./questionResales.reducer";
import notificationReducer from "./notification.reducer";
import userReducer from "./users.reducer";
import orderReducer from "./order.reducer";
import accountReducer from "./account.reducer";


const initialState: IRootStateType = {
    page: {area: "home", subArea: ""}
};

function rootReducer(state: IRootStateType = initialState, action: IActionBase): IRootStateType {
    switch (action.type) {
        case UPDATE_CURRENT_PATH:
            return { ...state, page: {area: action.area, subArea: action.subArea}};
        default:
            return state;
    }
}

const rootReducers: Reducer<IStateType> = combineReducers(
    {root: rootReducer,
    products: productsReducer,
    offers: offersReducer,
    cupons: cuponsReducer,
    subjects: subjectsReducer,
    questions: questionsReducer,
    subjectResales: subjectResalesReducer,
    questionResales: questionResalesReducer,
    notifications: notificationReducer,
    users: userReducer,
    orders: orderReducer,
    account: accountReducer,
});



export default rootReducers;