import { ISubject, SubjectModificationStatus } from "../models/subject.interface";
export const EDIT_SUBJECT: string = "EDIT_SUBJECT";
export const ADD_SUBJECT: string = "ADD_SUBJECT"; 
export const REMOVE_SUBJECT: string = "REMOVE_SUBJECT";
export const CHANGE_SUBJECT_PENDING_EDIT: string = "CHANGE_SUBJECT_PENDING_EDIT";
export const CLEAR_SUBJECT_PENDING_EDIT: string = "CLEAR_SUBJECT_PENDING_EDIT";
export const SET_MODIFICATION_STATE: string = "SET_MODIFICATION_STATE";
export const GET_SUBJECT_STATE: string = "GET_SUBJECT_STATE"

export function editSubject(subject: ISubject): IEditSubjectActionType {
    return { type: EDIT_SUBJECT, subject: subject };
}

export function addSubject(subject: ISubject): IAddSubjectActionType {
    return { type: ADD_SUBJECT, subject: subject };
}

export function removeSubject(id: string): IRemoveSubjectActionType {
    return { type: REMOVE_SUBJECT, id: id };
}

export function clearSelectedSubject(): IClearSelectedSubjectActionType {
    return { type: CLEAR_SUBJECT_PENDING_EDIT };
}

export function setModificationState(value: SubjectModificationStatus): ISetModificationStateActionType {
    return { type: SET_MODIFICATION_STATE, value: value };
}

export function changeSelectedSubject(subject: ISubject): IChangeSelectedSubjectActionType {
    return { type: CHANGE_SUBJECT_PENDING_EDIT, subject: subject };
}

export function getSubjectState(subject: ISubject): IGetSubjectState {
    return { type: GET_SUBJECT_STATE, subject: subject };
}





interface IEditSubjectActionType { type: string, subject: ISubject };
interface IAddSubjectActionType { type: string, subject: ISubject };
interface IRemoveSubjectActionType { type: string, id: string };
interface ISetModificationStateActionType { type: string, value: SubjectModificationStatus };
interface IClearSelectedSubjectActionType { type: string };
interface IChangeSelectedSubjectActionType { type: string, subject: ISubject };
interface IGetSubjectState { type: string, subject: ISubject };
