
import { IQuestionResaleState, IActionBase } from "../models/root.interface";
import {
    EDIT_QUESTIONRESALE, ADD_QUESTIONRESALE, REMOVE_QUESTIONRESALE, CHANGE_QUESTIONRESALE_PENDING_EDIT, CLEAR_QUESTIONRESALE_PENDING_EDIT, SET_MODIFICATION_STATE,
    GET_QUESTIONRESALE_STATE
} from "../actions/questionResales.actions";
import { IQuestionResale } from "../models/questionResale.interface";


const initialState: IQuestionResaleState = {
    modificationState: 0,
    selectedQuestionResale: null,
    questionResales: []
};

function questionResalesReducer(state: IQuestionResaleState = initialState, action: IActionBase): IQuestionResaleState {

    switch (action.type) {
        case EDIT_QUESTIONRESALE: {
            const foundIndex: number = state.questionResales.findIndex(pr => pr.id === action.questionResale.id);
            let questionResales: IQuestionResale[] = state.questionResales;
            questionResales[foundIndex] = action.questionResale;
            return { ...state, questionResales: questionResales };
        }
        case ADD_QUESTIONRESALE: {
            let maxId: number = Math.max.apply(Math, state.questionResales.map((o) => { return o.id; }));
            if(maxId === -Infinity) { maxId = 0; }
            return {...state, questionResales: [...state.questionResales, {...action.questionResale, id: maxId + 1, tableData: {id: maxId + 1}}]};
        }
        case REMOVE_QUESTIONRESALE: {
            return { ...state, questionResales: state.questionResales.filter(pr => pr.id !== Number(action.id)) };
        }
        case CHANGE_QUESTIONRESALE_PENDING_EDIT: {
            return { ...state, selectedQuestionResale: action.questionResale };
        }
        case CLEAR_QUESTIONRESALE_PENDING_EDIT: {
            return { ...state, selectedQuestionResale: null };
        }
        case SET_MODIFICATION_STATE: {
            return { ...state, modificationState: action.value };
        }
        case GET_QUESTIONRESALE_STATE: {
            let questionResales: IQuestionResale[] = action.questionResale;
            return { ...state, questionResales: questionResales };
        }
        default:
            return state;
    }
}


export default questionResalesReducer;