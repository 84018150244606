const services: any = {
  getOffersList: {
    method: 'get',
    endpoint: 'offer/list',
    source: 'kim'
  },
  putOffer: {
    method: 'put',
    endpoint: 'offer/{{id}}',
    source: 'kim'  
  },getCuponsList: {
    method: 'get',
    endpoint: 'cupon/list',
    source: 'kim'
  },
  putCupon: {
    method: 'put',
    endpoint: 'cupon/{{id}}',
    source: 'kim'  
  },
  postLogin: {
    method: 'post',
    endpoint: 'user/login',
    source: 'kim'  
  },
  commonQuestionsList: {
    method: 'get',
    endpoint: 'commonQuestions/list',
    source: 'kim'
  },
  putCommonQuestions: {
    method: 'put',
    endpoint: 'commonQuestions/{{id}}',
    source: 'kim'  
  },
  postCommonQuestions: {
    method: 'post',
    endpoint: 'commonQuestions',
    source: 'kim'  
  },
  deleteCommonQuestions: {
    method: 'delete',
    endpoint: 'commonQuestions/{{id}}',
    source: 'kim'  
  },
  getQuestion: {
    method: 'get',
    endpoint: 'questions/list/{{id}}',
    source: 'kim'  
  },
  putQuestion: {
    method: 'put',
    endpoint: 'questions/{{id}}',
    source: 'kim'  
  },
  postQuestion: {
    method: 'post',
    endpoint: 'questions',
    source: 'kim'  
  },
  deleteQuestion: {
    method: 'delete',
    endpoint: 'questions/{{id}}',
    source: 'kim'  
  },
  commonQuestionsResaleList: {
    method: 'get',
    endpoint: 'commonQuestionsResale/list',
    source: 'kim'
  },
  putCommonQuestionsResale: {
    method: 'put',
    endpoint: 'commonQuestionsResale/{{id}}',
    source: 'kim'  
  },
  postCommonQuestionsResale: {
    method: 'post',
    endpoint: 'commonQuestionsResale',
    source: 'kim'  
  },
  deleteCommonQuestionsResale: {
    method: 'delete',
    endpoint: 'commonQuestionsResale/{{id}}',
    source: 'kim'  
  },
  getQuestionResale: {
    method: 'get',
    endpoint: 'questionResales/list/{{id}}',
    source: 'kim'  
  },
  putQuestionResale: {
    method: 'put',
    endpoint: 'questionResales/{{id}}',
    source: 'kim'  
  },
  postQuestionResale: {
    method: 'post',
    endpoint: 'questionResales',
    source: 'kim'  
  },
  deleteQuestionResale: {
    method: 'delete',
    endpoint: 'questions/{{id}}',
    source: 'kim'  
  },
}

export default services