import { ptBR } from './languages'

// export function getLanguage() {
//   try {
//     console.log(languages)
//     return languages['ptBR']
//   } catch (error) {
//     console.log(error)
//   }
// }

export const language = ptBR
