/* eslint-disable react/jsx-no-target-blank */
import React, { Dispatch, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { IStateType, ISubjectResaleState } from "../../store/models/root.interface";
import {
  setModificationState,
  editSubjectResale,
  addSubjectResale,
  removeSubjectResale,
  getSubjectResaleState,
} from "../../store/actions/subjectResales.action";
import {
  ISubjectResale,
  SubjectResaleModificationStatus,
} from "../../store/models/subjectResale.interface";

import { addNotification } from "../../store/actions/notifications.action";

import services from "../../requester/services";
import requester from "../../requester/requester";
import {language} from "../../languages"

import MaterialTable from "material-table";
import MaterialTableIcons from "../../common/components/MaterialTableIcons";

import List from '@material-ui/icons/List';
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { MuiThemeProvider } from "@material-ui/core/styles";


export type subjectListProps = {
  onSelect?: (subjects: ISubjectResale) => void;
  children?: React.ReactNode;
};

function CommonQuestionsResaleList(props: subjectListProps): JSX.Element {
  const subjectResales: ISubjectResaleState = useSelector((state: IStateType) => state.subjectResales);
  const dispatch: Dispatch<any> = useDispatch();
  const token = useSelector((state: IStateType) => state.account.token);
  const history = useHistory();
  
  const commonQuestionLanguage = language.commonQuestion;

  const list = Array.from(subjectResales.subjectResales).map(d => {
    if(d.tableData){
    d.tableData.checked = false
  }
  return d
  });
  
  // const listStoreCategoryNameObject = Object.assign(
  //   {},
  //   ...list.map((item) => ({ [item.storeCategoryName]: item.storeCategoryName }))
  // );
  // const listStoreNameObject = Object.assign(
  //   {},
  //   ...list.map((item) => ({ [item.storeName]: item.storeName }))
  // );

  // const listStoreCategoryName = Object.keys(listStoreCategoryNameObject)
  //   .sort()
  //   .reduce(function (acc:any, key:any) { 
  //       acc[key] = listStoreCategoryNameObject[key];
  //       return acc;
  //   }, {});

  //   const listStoreName = Object.keys(listStoreNameObject)
  //   .sort()
  //   .reduce(function (acc:any, key:any) { 
  //       acc[key] = listStoreNameObject[key];
  //       return acc;
  //   }, {});

  

  const editSubjectList = async (subjectResale: ISubjectResale, id: string, array: any = []) => {
    console.log(subjectResale);
    const newSubject = {
      ...subjectResale,

    };
    if (subjectResale) {
      (await _putSubject(id, {
        ...subjectResale
      })) && dispatch(editSubjectResale(newSubject));
    }
  };

  const addSubjectList = async (subjectResale: any) => {
    console.log(subjectResale);
    const newSubject = {
      ...subjectResale
    };
    if (subjectResale) {
      (await _postSubject( {
        ...subjectResale
      })) && dispatch(addSubjectResale(newSubject));
    }
  };

  const removeSubjectList = async (id: string) => {

    if (id) {
      (await _deleteSubject(id)) && dispatch(removeSubjectResale(id));
    }
  };

  const _getQuestionsList = async () => {
    try {
      const { commonQuestionsResaleList: service } = services;
      const account = { token: token };
      const [error, response]: any = await requester(account, service);
      dispatch(getSubjectResaleState(response));
    } catch (error) {
      console.error(error);
    }
  };

  const _putSubject = async (id: string, body: object) => {
    try {
      const service = {
        ...services.putCommonQuestionsResale,
        endpoint: services.putCommonQuestionsResale.endpoint.replace("{{id}}", id),
      };
      const account = { token: token };
      const options = {
        data: body,
      };
      const [error, response]: any = await requester(account, service, options);
      if (!error) {
        dispatch(setModificationState(SubjectResaleModificationStatus.Edit));
      } else {
        dispatch(
          addNotification(
            "Ocorreu um erro",
            `Entre em contato com a equipe tecnica.`
          )
        );
      }
      return !error;
    } catch (error) {
      return false;
      console.error(error);
    }
  };

  const _postSubject = async (body: object) => {
    try {
      const service = services.postCommonQuestionsResale
      const account = { token: token };
      const options = {
        data: body,
      };
      const [error, response]: any = await requester(account, service, options);
      if (!error) {
        dispatch(setModificationState(SubjectResaleModificationStatus.Edit));
      } else {
        dispatch(
          addNotification(
            "Ocorreu um erro",
            `Entre em contato com a equipe tecnica.`
          )
        );
      }
      return !error;
    } catch (error) {
      return false;
      console.error(error);
    }
  };

  const _deleteSubject = async (id: string) => {
    try {
      const service = {
        ...services.deleteCommonQuestionsResale,
        endpoint: services.deleteCommonQuestionsResale.endpoint.replace("{{id}}", id),
      };
      const account = { token: token };
      
      const [error, response]: any = await requester(account, service);
      if (!error) {
        dispatch(setModificationState(SubjectResaleModificationStatus.Edit));
      } else {
        dispatch(
          addNotification(
            "Ocorreu um erro",
            `Entre em contato com a equipe tecnica.`
          )
        );
      }
      return !error;
    } catch (error) {
      return false;
      console.error(error);
    }
  };

  useEffect(() => {
    _getQuestionsList();
  }, []);

  const theme = createMuiTheme({
    overrides: {
      MuiCheckbox: {
        root: {
            padding: '5px',
        },
        colorSecondary: {
          // color: '#E74040',
            '&$checked': {
                // color: 'primary',
            },
        },
    },
    },
    props: {
      MuiCheckbox: {
        color: "primary",
        // style: {backgroundColor:'#4caf50'}
      },
      MuiRadio: {
        color: "primary",
      },
    },
    palette: {
      primary: {
        main: '#4caf50',
      },
      secondary: {
        main: '#432184' ,
      }
  
    },

  });

  return (
    <MuiThemeProvider theme={theme}>
    <MaterialTable
      icons={MaterialTableIcons}
      columns={[
        { title: commonQuestionLanguage.subjectId, field: "subjectId", type: "numeric", editable: "never" },
        { title: commonQuestionLanguage.title, field: "title" },
        {
          title: commonQuestionLanguage.status,
          field: "status",
          type: "boolean",
          editable: "always",
          // cellStyle:(data: any, rowData: any) => console.log(),
          render: (rowData) => (
            rowData?.status? <MaterialTableIcons.Check /> : <MaterialTableIcons.ThirdStateCheck  /> 
          ),
        },
      ]}
      data={list || []}
      options={{
        filtering: true,
        columnsButton: true,
        search: false,
        selection: true,
        rowStyle: data => {
          // console.log(data)
        return data.tableData.id % 2
          ? { background: "#F1F0FF" }
          : { background: "white" }
        }
        // headerStyle: {backgroundColor: "rgba(67, 33, 132, 0.22)"}
      }}
      title={commonQuestionLanguage.list}
      localization={commonQuestionLanguage.localization}
      editable={{
        onRowUpdate: async (newData, oldData) => editSubjectList(newData, newData.subjectId.toString()),
        onRowAdd: async (newData) => addSubjectList(newData),
        // onRowDelete: async (oldData) => removeSubjectList(oldData.subjectId.toString()),
      }}
      actions={[
        {
          icon: List,
          tooltip: commonQuestionLanguage.listIcon,
          position: 'row',
          onClick: (event, data:any ) => {
            history.push(`questionResales/${data.subjectId}`,{questionSubjectName:data.title})
          },
        }
      ]}
    />
    </MuiThemeProvider>
  );
}

export default CommonQuestionsResaleList;
