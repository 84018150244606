/* eslint-disable react/jsx-no-target-blank */
import React, { Dispatch, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IStateType, ICuponState } from "../../store/models/root.interface";
import {
  setModificationState,
  editCupon,
  getCuponState,
} from "../../store/actions/cupons.actions";
import {
  ICupon,
  CuponModificationStatus,
} from "../../store/models/cupon.interface";

import { addNotification } from "../../store/actions/notifications.action";

import services from "../../requester/services";
import requester from "../../requester/requester";
import {language} from "../../languages"

import MaterialTable from "material-table";
import MaterialTableIcons from "../../common/components/MaterialTableIcons";

import Link from "@material-ui/icons/Link";
import Refresh from "@material-ui/icons/Refresh";
import CheckBox from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import Star from '@material-ui/icons/Star';
import StarOutline from '@material-ui/icons/StarBorderOutlined';
// import Clear from '@material-ui/icons/Clear';
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { MuiThemeProvider } from "@material-ui/core/styles";


export type cuponListProps = {
  onSelect?: (cupons: ICupon) => void;
  children?: React.ReactNode;
};

function CuponList(props: cuponListProps): JSX.Element {
  const cupons: ICuponState = useSelector((state: IStateType) => state.cupons);
  const dispatch: Dispatch<any> = useDispatch();
  const token = useSelector((state: IStateType) => state.account.token);
  
  const cuponLanguage = language.cuponLanguage;

  const list = Array.from(cupons.cupons).map(d => {
    if(d.tableData){
    d.tableData.checked = false
  }
  return d
  });
  
  const listStoreCategoryNameObject = Object.assign(
    {},
    ...list.map((item) => ({ [item.storeCategoryName]: item.storeCategoryName }))
  );
  const listStoreNameObject = Object.assign(
    {},
    ...list.map((item) => ({ [item.storeName]: item.storeName }))
  );

  const listStoreCategoryName = Object.keys(listStoreCategoryNameObject)
    .sort()
    .reduce(function (acc:any, key:any) { 
        acc[key] = listStoreCategoryNameObject[key];
        return acc;
    }, {});

    const listStoreName = Object.keys(listStoreNameObject)
    .sort()
    .reduce(function (acc:any, key:any) { 
        acc[key] = listStoreNameObject[key];
        return acc;
    }, {});

  

  const editCuponList = async (cupon: ICupon, id: string, array: any = []) => {
    console.log(cupon);
    const newCupon = {
      ...cupon,
      status: cupon.status,
      cuponTop: cupon.cuponTop,
    };
    if (cupon) {
      (await _putCupon(id, {
        status: cupon.status,
        cuponTop: cupon.cuponTop,
      })) && array.length > 0
      ? array.map((item: ICupon) =>{
        const newCuponList = {
          ...item,
          status: typeof cupon.status === "undefined" ? item.status : cupon.status,
          cuponTop: typeof cupon.cuponTop === "undefined" ? item.cuponTop : cupon.cuponTop,
        };
          dispatch(editCupon({ ...item, status: newCuponList.status , cuponTop: newCuponList.cuponTop, }))
        }
        )
      : dispatch(editCupon(newCupon));
    }
  };

  const _getCuponsList = async () => {
    try {
      const { getCuponsList: service } = services;
      const account = { token: token };
      const [error, response]: any = await requester(account, service);
      dispatch(getCuponState(response));
    } catch (error) {
      console.error(error);
    }
  };

  const _putCupon = async (id: string, body: object) => {
    try {
      const service = {
        ...services.putCupon,
        endpoint: services.putCupon.endpoint.replace("{{id}}", id),
      };
      const account = { token: token };
      const options = {
        data: body,
      };
      const [error, response]: any = await requester(account, service, options);
      if (!error) {
        dispatch(setModificationState(CuponModificationStatus.Edit));
      } else {
        dispatch(
          addNotification(
            "Ocorreu um erro",
            `Entre em contato com a equipe tecnica.`
          )
        );
      }
      return !error;
    } catch (error) {
      return false;
      console.error(error);
    }
  };

  useEffect(() => {
    _getCuponsList();
  }, []);

  const theme = createMuiTheme({
    overrides: {
      MuiCheckbox: {
        root: {
            padding: '5px',
        },
        colorSecondary: {
          // color: '#E74040',
            '&$checked': {
                // color: 'primary',
            },
        },
    },
    },
    props: {
      MuiCheckbox: {
        color: "primary",
        // style: {backgroundColor:'#4caf50'}
      },
      MuiRadio: {
        color: "primary",
      },
    },
    palette: {
      primary: {
        main: '#4caf50',
      },
      secondary: {
        main: '#432184' ,
      }
  
    },

  });

  return (
    <MuiThemeProvider theme={theme}>
    <MaterialTable
      icons={MaterialTableIcons}
      columns={[
        { title: cuponLanguage.id, field: "id", type: "numeric", editable: "never" },
        {
          title: cuponLanguage.top,
          field: "cuponTop",
          type: "boolean",
          editable: "onUpdate", 
          render: (rowData) => (
            rowData.cuponTop ? <MaterialTableIcons.Check /> : <MaterialTableIcons.ThirdStateCheck /> 
          ),
        },
        {
          title: cuponLanguage.status,
          field: "status",
          type: "boolean",
          editable: "onUpdate",
          // cellStyle:(data: any, rowData: any) => console.log(),
          render: (rowData) => (
            rowData.status ? <MaterialTableIcons.Check /> : <MaterialTableIcons.ThirdStateCheck /> 
          ),
        },
        { title: cuponLanguage.description, field: "description", editable: "never" },
        {
          title: cuponLanguage.storeCategoryName,
          field: "storeCategoryName",
          editable: "never",
          lookup: listStoreCategoryName,
        },
        {
          title: "Loja",
          field: "storeName",
          editable: "never",
          lookup: listStoreName,
          cellStyle:{fontWeight:'bold'}
        },
        { title: cuponLanguage.code, field: "code", editable: "never" },
        {
          title: cuponLanguage.discount,
          field: "discount",
          type: "currency",
          editable: "never",
          currencySetting: {
            currencyCode: "BRL",
          },
          filtering: false,
          cellStyle:{fontWeight:'bold'}
        },
        {
          title: cuponLanguage.expirationDate,
          field: "expirationDate",
          filtering: false,
          editable: "never",
        },
      ]}
      data={list || []}
      options={{
        filtering: true,
        columnsButton: true,
        search: false,
        selection: true,
        rowStyle: data => {
          // console.log(data)
        return data.tableData.id % 2
          ? { background: "#F1F0FF" }
          : { background: "white" }
        }
        // headerStyle: {backgroundColor: "rgba(67, 33, 132, 0.22)"}
      }}
      title={cuponLanguage.list}
      localization={cuponLanguage.localization}
      editable={{
        onRowUpdate: async (newData, oldData) => editCuponList(newData, newData.id.toString()),
      }}
      actions={[
        {
          icon: ()=> <MaterialTableIcons.CheckBox />,
          iconProps: { color: "secondary"},
          tooltip: cuponLanguage.checkAll,
          onClick: (event, rowData) => {
            const itens: any = rowData;
            const body = { id: 0, description: "", code: "", discount: 0,storeCategoryId:0, storeCategoryName: "",storeId:0, storeName:'', link: '', expirationDate:"", status:true};
            editCuponList(
              body,
              itens
                .reduce(
                  (prev: any, curr: { id: any }) => [...prev, curr.id],
                  []
                )
                .join(","),
              rowData
            );
          },
        },
        {
          icon: ()=> <MaterialTableIcons.CheckBoxOutlineBlank />,
          tooltip: cuponLanguage.notcheckAll,
          onClick: (event, rowData) => {
            const itens: any = rowData;
            const body = { id: 0, description: "", code: "", discount: 0,storeCategoryId:0, storeCategoryName: "",storeId:0, storeName:'', link: '', expirationDate:"", status:false};
            editCuponList(
              body,
              itens
                .reduce(
                  (prev: any, curr: { id: any }) => [...prev, curr.id],
                  []
                )
                .join(","),
              rowData
            );
          },
        },
        {
          icon: ()=> <MaterialTableIcons.Star />,
          tooltip: cuponLanguage.checkTopAll,
          onClick: (event, rowData) => {
            const itens: any = rowData;
            const body = { id: 0, description: "", code: "", discount: 0,storeCategoryId:0, storeCategoryName: "",storeId:0, storeName:'', link: '', expirationDate:"",cuponTop:true};
            editCuponList(
              body,
              itens
                .reduce(
                  (prev: any, curr: { id: any }) => [...prev, curr.id],
                  []
                )
                .join(","),
              rowData
            );
          },
        },
        {
          icon: ()=> <MaterialTableIcons.StarBorderOutlined />,
          tooltip: cuponLanguage.notcheckTopAll,
          onClick: (event, rowData) => {
            const itens: any = rowData;
            const body = { id: 0, description: "", code: "", discount: 0,storeCategoryId:0, storeCategoryName: "",storeId:0, storeName:'', link: '', expirationDate:"",cuponTop:false};
            editCuponList(
              body,
              itens
                .reduce(
                  (prev: any, curr: { id: any }) => [...prev, curr.id],
                  []
                )
                .join(","),
              rowData
            );
          },
        },
        {
          icon: Refresh,
          tooltip: cuponLanguage.refresh,
          isFreeAction: true,
          onClick: (event) => _getCuponsList(),
        },
      ]}
    />
    </MuiThemeProvider>
  );
}

export default CuponList;
