export interface ICupon {
    id: number;
    description: string;
    code: string;
    discount: number;
    storeCategoryId: number;
    storeCategoryName: string;
    storeId: number;
    storeName: string;
    link: string;
    expirationDate: string;
    cuponTop?: boolean;
    status?: boolean;
    icon?:any,
    tableData?:any
}

export enum CuponModificationStatus {
    None = 0,
    Create = 1,
    Edit = 2
}