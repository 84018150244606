export interface IOffer {
    id: number;
    description: string;
    storeCategoryId: number;
    storeCategoryName: string;
    storeId: number;
    storeName: string;
    link: string;
    image: string;
    price: number;
    priceOffer: number;
    amount?: number;
    offerTop?: boolean;
    status?: boolean;
    icon?:any,
    tableData?:any
}

export enum OfferModificationStatus {
    None = 0,
    Create = 1,
    Edit = 2
}