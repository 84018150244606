
import { ICuponState, IActionBase } from "../models/root.interface";
import {
    EDIT_CUPON, CHANGE_CUPON_PENDING_EDIT, CLEAR_CUPON_PENDING_EDIT, SET_MODIFICATION_STATE,
    GET_CUPON_STATE
} from "../actions/cupons.actions";
import { ICupon } from "../models/cupon.interface";


const initialState: ICuponState = {
    modificationState: 0,
    selectedCupon: null,
    cupons: []
};

function cuponsReducer(state: ICuponState = initialState, action: IActionBase): ICuponState {
    
    switch (action.type) {
        case EDIT_CUPON: {
           const foundIndex: number = state.cupons.findIndex(pr => pr.id === action.cupon.id);
           let cupons: ICupon[] = state.cupons;
           cupons[foundIndex] = action.cupon;
           return { ...state, cupons: cupons };
        }
        case CHANGE_CUPON_PENDING_EDIT: {
           return { ...state, selectedCupon: action.cupon };
        }
        case CLEAR_CUPON_PENDING_EDIT: {
           return { ...state, selectedCupon: null };
        }
        case SET_MODIFICATION_STATE: {
           return { ...state, modificationState: action.value };
        }
        case GET_CUPON_STATE: {
            let cupons: ICupon[] = action.cupon;
            return { ...state, cupons: cupons };
         }
        default:
            return state;
    }
}


export default cuponsReducer;