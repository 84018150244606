import { ISubjectResale, SubjectResaleModificationStatus } from "../models/subjectResale.interface";
export const EDIT_SUBJECTRESALE: string = "EDIT_SUBJECTRESALE";
export const ADD_SUBJECTRESALE: string = "ADD_SUBJECTRESALE"; 
export const REMOVE_SUBJECTRESALE: string = "REMOVE_SUBJECTRESALE";
export const CHANGE_SUBJECTRESALE_PENDING_EDIT: string = "CHANGE_SUBJECTRESALE_PENDING_EDIT";
export const CLEAR_SUBJECTRESALE_PENDING_EDIT: string = "CLEAR_SUBJECTRESALE_PENDING_EDIT";
export const SET_MODIFICATION_STATE: string = "SET_MODIFICATION_STATE";
export const GET_SUBJECTRESALE_STATE: string = "GET_SUBJECTRESALE_STATE"

export function editSubjectResale(subjectResale: ISubjectResale): IEditSubjectResaleActionType {
    return { type: EDIT_SUBJECTRESALE, subjectResale: subjectResale };
}

export function addSubjectResale(subjectResale: ISubjectResale): IAddSubjectResaleActionType {
    return { type: ADD_SUBJECTRESALE, subjectResale: subjectResale };
}

export function removeSubjectResale(id: string): IRemoveSubjectResaleActionType {
    return { type: REMOVE_SUBJECTRESALE, id: id };
}

export function clearSelectedSubjectResale(): IClearSelectedSubjectResaleActionType {
    return { type: CLEAR_SUBJECTRESALE_PENDING_EDIT };
}

export function setModificationState(value: SubjectResaleModificationStatus): ISetModificationStateActionType {
    return { type: SET_MODIFICATION_STATE, value: value };
}

export function changeSelectedSubjectResale(subjectResale: ISubjectResale): IChangeSelectedSubjectResaleActionType {
    return { type: CHANGE_SUBJECTRESALE_PENDING_EDIT, subjectResale: subjectResale };
}

export function getSubjectResaleState(subjectResale: ISubjectResale): IGetSubjectResaleState {
    return { type: GET_SUBJECTRESALE_STATE, subjectResale: subjectResale };
}





interface IEditSubjectResaleActionType { type: string, subjectResale: ISubjectResale };
interface IAddSubjectResaleActionType { type: string, subjectResale: ISubjectResale };
interface IRemoveSubjectResaleActionType { type: string, id: string };
interface ISetModificationStateActionType { type: string, value: SubjectResaleModificationStatus };
interface IClearSelectedSubjectResaleActionType { type: string };
interface IChangeSelectedSubjectResaleActionType { type: string, subjectResale: ISubjectResale };
interface IGetSubjectResaleState { type: string, subjectResale: ISubjectResale };
