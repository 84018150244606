/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useSelector } from "react-redux";
import { IStateType, IProductState } from "../../store/models/root.interface";
import { IProduct } from "../../store/models/product.interface";

export type productListProps = {
  onSelect?: (product: IProduct) => void;
  children?: React.ReactNode;
};

function ProductList(props: productListProps): JSX.Element  {
  const products: IProductState = useSelector((state: IStateType) => state.products);

  const productElements: (JSX.Element | null)[] = products.products.map(product => {
    if (!product) { return null; }
    return (<tr className={`table-row ${(products.selectedProduct && products.selectedProduct.id === product.id) ? "selected" : ""}`}
      onClick={() => {
        if(props.onSelect) props.onSelect(product);
      }}
      key={`product_${product.id}`}>
      <th scope="row">{product.id}</th>
      <td>{product.description}</td>
      <td>{product.category}</td>
      <td>{product.price}</td>
      <td>{product.priceOffer}</td>
      <td>
        <a  target="_blank" rel='noreferrer' href="http://www.google.com">
          <i className="fas fa-external-link-alt"></i>
        </a>
      </td>
      <td>        <img className="img-profile" alt=""
          src={product.image} />
       </td>
      <td>{product.favorite}</td>
    </tr>);
  });


  return (
    <div className="table-responsive portlet">
      <table className="table">
        <thead className="thead-light">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Descrição</th>
            <th scope="col">Categoria</th>
            <th scope="col">Preço</th>
            <th scope="col">Oferta</th>
            <th scope="col">Link</th>
            <th scope="col">Imagem</th>
            <th scope="col">Favorito</th>
          </tr>
        </thead>
        <tbody>
          {productElements}
        </tbody>
      </table>
    </div>

  );
}

export default ProductList;
