import React, { Fragment, Dispatch, useState, useEffect } from "react";
import OfferList from "./OffersList";
import "./Offers.css";
import { useDispatch, useSelector } from "react-redux";
import {  IStateType } from "../../store/models/root.interface";
import { setModificationState, changeSelectedOffer, getOfferState } from "../../store/actions/offers.actions";
import { OfferModificationStatus, IOffer } from "../../store/models/offer.interface";

import services from "../../requester/services"
import requester from "../../requester/requester"

const Offers: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const token = useSelector((state: IStateType) => state.account.token);



  function onOffersSelect(offer: IOffer): void {
    dispatch(changeSelectedOffer(offer));
    dispatch(setModificationState(OfferModificationStatus.None));
  }

  const _getOffersList = async () => {
    try {
      const { getOffersList: service } = services
      const account = { token: token }
      const [error, response]:any = await requester(account, service)
      dispatch(getOfferState(response))
      } catch (error) {
        console.error(error)
      }
}


useEffect(() => {
  _getOffersList()
}, []);

  return (
    <Fragment>
      <OfferList onSelect={onOffersSelect} />
    </Fragment>

  );
};

export default Offers;
