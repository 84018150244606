/* eslint-disable react/jsx-no-target-blank */
import React, { Dispatch } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IStateType, IOfferState } from "../../store/models/root.interface";
import {
  setModificationState,
  editOffer,
  getOfferState,
} from "../../store/actions/offers.actions";
import {
  IOffer,
  OfferModificationStatus,
} from "../../store/models/offer.interface";
import { addNotification } from "../../store/actions/notifications.action";
import services from "../../requester/services";
import requester from "../../requester/requester";
import { language } from "../../languages";

import MaterialTable from "material-table";
import MaterialTableIcons from "../../common/components/MaterialTableIcons";

import Link from "@material-ui/icons/Link";
import Refresh from "@material-ui/icons/Refresh";
import CheckBox from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";

// import Clear from '@material-ui/icons/Clear';
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { MuiThemeProvider } from "@material-ui/core/styles";

export type offerListProps = {
  onSelect?: (offers: IOffer) => void;
  children?: React.ReactNode;
};

function OfferList(props: offerListProps): JSX.Element {
  const offers: IOfferState = useSelector((state: IStateType) => state.offers);
  const dispatch: Dispatch<any> = useDispatch();
  const token = useSelector((state: IStateType) => state.account.token);
  const offerLanguage = language.offerLanguage;
  const list = Array.from(offers.offers).map(d => {
    if(d.tableData){
    d.tableData.checked = false
  }
  return d
  });
  const listStoreCategoryNameObject = Object.assign(
    {},
    ...list.map((item) => ({ [item.storeCategoryName]: item.storeCategoryName }))
  );
  const listStoreNameObject = Object.assign(
    {},
    ...list.map((item) => ({ [item.storeName]: item.storeName }))
  );

  const listStoreCategoryName = Object.keys(listStoreCategoryNameObject)
    .sort()
    .reduce(function (acc:any, key:any) { 
        acc[key] = listStoreCategoryNameObject[key];
        return acc;
    }, {});

    const listStoreName = Object.keys(listStoreNameObject)
    .sort()
    .reduce(function (acc:any, key:any) { 
        acc[key] = listStoreNameObject[key];
        return acc;
    }, {});

  const editOfferList = async (offer: IOffer, id: string, array: any = []) => {
    console.log(offer);
    const newOffer = {
      ...offer,
      status: offer.status,
      offerTop: offer.offerTop,
    };
    if (offer) {
      (await _putOffer(id, {
        status: newOffer.status,
        offerTop: newOffer.offerTop,
      })) && array.length > 0
        ? array.map((item: IOffer) =>{
          const newOfferList = {
            ...item,
            status: typeof offer.status === "undefined" ? item.status : offer.status,
            offerTop: typeof offer.offerTop === "undefined" ? item.offerTop : offer.offerTop,
          };
            dispatch(editOffer({ ...item, status: newOfferList.status,offerTop: newOfferList.offerTop, }))
        }
          )
        : dispatch(editOffer(newOffer));
    }
  };

  const _putOffer = async (id: string, body: object) => {
    try {
      const service = {
        ...services.putOffer,
        endpoint: services.putOffer.endpoint.replace("{{id}}", id),
      };
      const account = { token: token };
      const options = {
        data: body,
      };
      const [error, response]: any = await requester(account, service, options);
      if (!error) {
        dispatch(setModificationState(OfferModificationStatus.None));
      } else {
        dispatch(
          addNotification(
            "Ocorreu um erro",
            `Entre em contato com a equipe tecnica.`
          )
        );
      }
      return !error;
    } catch (error) {
      return false;
      console.error(error);
    }
  };

  const _getOffersList = async () => {
    try {
      const { getOffersList: service } = services;
      const account = { token: token };
      const [error, response]: any = await requester(account, service);
      dispatch(getOfferState(response));
    } catch (error) {
      console.error(error);
    }
  };

  const theme = createMuiTheme({
    overrides: {
      MuiCheckbox: {
        root: {
            padding: '5px',
        },
        colorSecondary: {
          // color: '#E74040',
            '&$checked': {
                // color: 'primary',
            },
        },
    },
    },
    props: {
      MuiCheckbox: {
        color: "primary",
        // style: {backgroundColor:'#4caf50'}
      },
      MuiRadio: {
        color: "primary",
      },
    },
    palette: {
      primary: {
        main: '#4caf50',
      },
      secondary: {
        main: '#432184' ,
      }
  
    },

  });

  return (
    <MuiThemeProvider theme={theme}>
    <MaterialTable
      icons={MaterialTableIcons}
      columns={[
        {
          title: offerLanguage.id,
          field: "id",
          type: "numeric",
          editable: "never",
        },
        {
          title: offerLanguage.top,
          field: "offerTop",
          type: "boolean",
          editable: "onUpdate", 
          render: (rowData) => (
            rowData.offerTop ? <MaterialTableIcons.Check /> : <MaterialTableIcons.ThirdStateCheck /> 
          ),
        },
        {
          title: offerLanguage.status,
          field: "status",
          type: "boolean",
          editable: "onUpdate",
          render: (rowData) => (
            rowData.status ? <MaterialTableIcons.Check /> : <MaterialTableIcons.ThirdStateCheck /> 
          ),
        },
        {
          title: offerLanguage.description,
          field: "description",
          editable: "never",
        },
        {
          title: offerLanguage.storeCategoryName,
          field: "storeCategoryName",
          editable: "never",
          lookup: listStoreCategoryName,
        },
        {
          title: "Loja",
          field: "storeName",
          editable: "never",
          lookup: listStoreName,
          cellStyle:{fontWeight:'bold'}
        },
        {
          title: offerLanguage.price,
          field: "price",
          type: "currency",
          editable: "never",
          currencySetting: {
            currencyCode: "BRL",
          },
          cellStyle:{fontWeight:'bold'},
          filtering: false
        },
        {
          title: offerLanguage.priceOffer,
          field: "priceOffer",
          type: "currency",
          editable: "never",
          currencySetting: {
            currencyCode: "BRL",
          },
          cellStyle:{fontWeight:'bold'},
          filtering: false
        },
        {
          title: offerLanguage.image,
          field: "image",
          render: (rowData) => (
            <a target="_blank" rel="noreferrer" href={rowData.link}>
              <img src={rowData.image} className="img-offer" />
            </a>
          ),
          editable: "never",
          filtering: false,
        },
      ]}
      data={list || []}
      options={{
        filtering: true,
        columnsButton: true,
        search: false,
        selection: true,
        rowStyle: data => {
          // console.log(data)
        return data.tableData.id % 2
          ? { background: "#F1F0FF" }
          : { background: "white" }
        }
        // headerStyle: {backgroundColor: "rgba(67, 33, 132, 0.22)"}
      }}
      title={offerLanguage.list}
      localization={offerLanguage.localization}
      editable={{
        onRowUpdate: async (newData, oldData) =>
          editOfferList(newData, newData.id.toString()),
      }}
      actions={[
        {
          icon: ()=> <MaterialTableIcons.CheckBox />,
          tooltip: offerLanguage.checkAll,
          onClick: (event, rowData) => {
            const itens: any = rowData;
            const body = {
              id: 0,
              description: "",
              price: 0,
              storeCategoryId: 0,
              storeCategoryName: "",
              storeId: 0,
              storeName: "",
              link: "",
              image: "",
              priceOffer: 0,
              status: true,
            };
            editOfferList(
              body,
              itens
                .reduce(
                  (prev: any, curr: { id: any }) => [...prev, curr.id],
                  []
                )
                .join(","),
              rowData
            );
          },
        },
        {
          icon: ()=> <MaterialTableIcons.CheckBoxOutlineBlank />,
          tooltip: offerLanguage.notcheckAll,
          onClick: (event, rowData) => {
            const itens: any = rowData;
            const body = {
              id: 0,
              description: "",
              price: 0,
              storeCategoryId: 0,
              storeCategoryName: "",
              storeId: 0,
              storeName: "",
              link: "",
              image: "",
              priceOffer: 0,
              status: false,
            };
            editOfferList(
              body,
              itens
                .reduce(
                  (prev: any, curr: { id: any }) => [...prev, curr.id],
                  []
                )
                .join(","),
              rowData
            );
          }
        },
        {
            icon: ()=> <MaterialTableIcons.Star />,
            tooltip: offerLanguage.checkTopAll,
            onClick: (event, rowData) => {
              const itens: any = rowData;
              const body = {
                id: 0,
                description: "",
                price: 0,
                storeCategoryId: 0,
                storeCategoryName: "",
                storeId: 0,
                storeName: "",
                link: "",
                image: "",
                priceOffer: 0,
                offerTop: true,
              };
              editOfferList(
                body,
                itens
                  .reduce(
                    (prev: any, curr: { id: any }) => [...prev, curr.id],
                    []
                  )
                  .join(","),
                rowData
              );
            },
        },
        {
            icon: ()=> <MaterialTableIcons.StarBorderOutlined />,
            tooltip: offerLanguage.notcheckTopAll,
            onClick: (event, rowData) => {
              const itens: any = rowData;
              const body = {
                id: 0,
                description: "",
                price: 0,
                storeCategoryId: 0,
                storeCategoryName: "",
                storeId: 0,
                storeName: "",
                link: "",
                image: "",
                priceOffer: 0,
                offerTop: false,
              };
              editOfferList(
                body,
                itens
                  .reduce(
                    (prev: any, curr: { id: any }) => [...prev, curr.id],
                    []
                  )
                  .join(","),
                rowData
              );
            },
        },
        {
          icon: Refresh,
          tooltip: offerLanguage.refresh,
          isFreeAction: true,
          onClick: (event) => _getOffersList(),
        },
      ]}
    />
    </MuiThemeProvider>
  );
}

export default OfferList;
