import {ILanguage, ILanguageLocalization, IErrorGeneric} from '../store/models/language.interface'

const  localization : ILanguageLocalization = {
  header: {
    actions: "Ações",
  },
  body: {
    emptyDataSourceMessage: "Nenhum registro para exibir",
    addTooltip: "Adicionar",
    editTooltip: "Editar",
    deleteTooltip: "Deletar",
    editRow: {
      cancelTooltip: "Cancelar",
      saveTooltip: "Confimar",
      deleteText: "Deseja apagar essa linha ?"
    },
  },
  toolbar: {
    searchTooltip: "Pesquisar",
    searchPlaceholder: "Pesquisar",
    nRowsSelected: '{0} linha(s) selecionada(s)'
  },
  pagination: {
    labelRowsSelect: "linhas",
    labelDisplayedRows: "{count} de {from}-{to}",
    firstTooltip: "Primeira página",
    previousTooltip: "Página anterior",
    nextTooltip: "Próxima página",
    lastTooltip: "Última página",
  },
}

const errorGeneric: IErrorGeneric = {
  title: "Ocorreu um erro",
  text: "Entre em contato com a equipe tecnica."
}


const common = {
  materialTable:{
    localization:localization
  },
  errorGeneric:errorGeneric
}

export const ptBR : ILanguage = {
  cuponLanguage: {
    localization: common.materialTable.localization,
    notification:common.errorGeneric,
    list:'Lista de Cupons',
    id:'#',
    cupons:'Cupons',
    top:'Top',
    status:'Ativo',
    description:'Descrição',
    code:'Código',
    discount:'Desconto',
    storeCategoryName:'Categoria',
    storeName:'Loja',
    expirationDate:'Expiração',
    link:'Link de redirecionamento',
    refresh: 'Recarregar',
    checkAll: 'Ativar todos os itens selecionado.',
    notcheckAll: 'Desativar todos os itens selecionado.',
    checkTopAll: 'Ativar como top todos os itens selecionado.',
    notcheckTopAll: 'Desativar como top todos os itens selecionado.'
  },
  offerLanguage: {
    localization: common.materialTable.localization,
    notification:common.errorGeneric,
    link:'Link de redirecionamento',
    list:'Lista de Ofertas',
    id: '#',
    description: 'Descrição',
    storeCategoryName: 'Categoria',
    storeName: 'Loja',
    image: 'Imagem',
    price: 'Preço',
    priceOffer: 'Oferta',
    status: 'Ativo',
    top:'Top',
    refresh: 'Recarregar',
    checkAll: 'Ativar todos os itens selecionado.',
    notcheckAll: 'Desativar todos os itens selecionado.',
    checkTopAll: 'Ativar como top todos os itens selecionado.',
    notcheckTopAll: 'Desativar como top todos os itens selecionado.'
  },
  commonQuestion: {
    localization: common.materialTable.localization,
    notification:common.errorGeneric,
    link:'Link de redirecionamento',
    list:'Lista de Topicos',
    subjectId: '#',
    title: 'Titulo',
    status: 'Ativo',
    refresh: 'Recarregar',
    listIcon: 'Lista de Perguntas'
  },
  question: {
    localization: common.materialTable.localization,
    notification:common.errorGeneric,
    link:'Link de redirecionamento',
    list:'Lista de Perguntas',
    id: '#',
    subjectId: 'Ordem',
    name: 'Pergunta',
    answer:'Resposta',
    status: 'Ativo',
    refresh: 'Recarregar',
    likes: 'likes',
    deslikes: 'deslikes',
    listIcon: 'Lista de Perguntas',
    addTooltip: "Adicionar"
  }
}


export default ptBR
