
import { ISubjectResaleState, IActionBase } from "../models/root.interface";
import {
    EDIT_SUBJECTRESALE, ADD_SUBJECTRESALE, REMOVE_SUBJECTRESALE, CHANGE_SUBJECTRESALE_PENDING_EDIT, CLEAR_SUBJECTRESALE_PENDING_EDIT, SET_MODIFICATION_STATE,
    GET_SUBJECTRESALE_STATE
} from "../actions/subjectResales.action";
import { ISubjectResale } from "../models/subjectResale.interface";


const initialState: ISubjectResaleState = {
    modificationState: 0,
    selectedSubjectResale: null,
    subjectResales: []
};

function subjectResalesReducer(state: ISubjectResaleState = initialState, action: IActionBase): ISubjectResaleState {

    switch (action.type) {
        case EDIT_SUBJECTRESALE: {
            const foundIndex: number = state.subjectResales.findIndex(pr => pr.subjectId === action.subjectResale.subjectId);
            let subjects: ISubjectResale[] = state.subjectResales;
            subjects[foundIndex] = action.subjectResale;
            return { ...state, subjectResales: subjects };
        }
        case ADD_SUBJECTRESALE: {
            let maxId: number = Math.max.apply(Math, state.subjectResales.map((o) => { return o.subjectId; }));
            if(maxId === -Infinity) { maxId = 0; }
            return {...state, subjectResales: [...state.subjectResales, {...action.subjectResale, subjectId: maxId + 1, question: [], tableData: {id: maxId + 1}}]};
        }
        case REMOVE_SUBJECTRESALE: {
            return { ...state, subjectResales: state.subjectResales.filter(pr => pr.subjectId !== Number(action.id)) };
        }
        case CHANGE_SUBJECTRESALE_PENDING_EDIT: {
            return { ...state, selectedSubjectResale: action.subjectResale };
        }
        case CLEAR_SUBJECTRESALE_PENDING_EDIT: {
            return { ...state, selectedSubjectResale: null };
        }
        case SET_MODIFICATION_STATE: {
            return { ...state, modificationState: action.value };
        }
        case GET_SUBJECTRESALE_STATE: {
            let subjectResales: ISubjectResale[] = action.subjectResale;
            return { ...state, subjectResales:  subjectResales};
        }
        default:
            return state;
    }
}


export default subjectResalesReducer;