import React, { useState, Fragment, FormEvent, Dispatch } from "react";
import {
  useParams,
  useLocation,
  useHistory 
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import QuestionsList from "./QuestionsList";
import "./Questions.css";

import services from "../../requester/services";
import requester from "../../requester/requester";
import { addNotification } from "../../store/actions/notifications.action";
import {
  setModificationState,
  changeSelectedQuestion,
  addQuestion,
  clearSelectedQuestion
} from "../../store/actions/questions.actions";
import {
  QuestionModificationStatus,
  IQuestion,
} from "../../store/models/question.interface";


import NumberInput from "../../common/components/NumberInput";
import CheckboxMaterialUi from "../../common/components/CheckboxMaterialUi";
import TextInput from "../../common/components/TextInput";
import RichText from "../../common/components/RichTextEditor";

import { IStateType, IQuestionState } from "../../store/models/root.interface";

import { OnChangeModel, IQuestionFormState } from "../../common/types/Form.types";

import { ChangeEvent } from 'react';

const QuestionsAdd: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  let { id } = useParams();
  const location = useLocation();
  let history = useHistory();
  const { questionSubjectName } = location.state as any || "";

  const questions: IQuestionState = useSelector((state: IStateType) => state.questions);
  const token = useSelector((state: IStateType) => state.account.token);

  const [formState, setFormState] = useState({
    orderNumber: { error: "", value: 0 },
    name: { error: "", value: "" },
    answer: { error: "", value: "" },
    status: { error: "", value: false }
  });

  function isFormInvalid(): boolean {
    return (formState.orderNumber.error || formState.name.error
      || formState.answer.error
    ) as unknown as boolean;
  }

  function saveUser(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    if (isFormInvalid()) {
      return;
    }

    // let saveUserFn: Function = addQuestion;
    addQuestionList(formState);
  }

  const addQuestionList = async (item: IQuestionFormState) => {
    console.log(item);
    const newQuestion = {
      orderNumber: Number(item.orderNumber.value),
      name: item.name.value,
      answer: item.answer.value,
      status: item.status?.value||false,
      questionSubjectId: Number(id),
      id: 0
    };
    if (item) {
      (await _postQuestion({
        ...newQuestion
      })) && dispatch(addQuestion(newQuestion));
    }
  };

  const _postQuestion = async (body: object) => {
    try {
      const service = services.postQuestion
      const account = { token: token };
      const options = {
        data: body,
      };
      const [error, response]: any = await requester(account, service, options);
      if (!error) {
        dispatch(setModificationState(QuestionModificationStatus.Edit));
        dispatch(addNotification("Pergunta Criada", ``));
        cancelForm()
      } else {
        dispatch(
          addNotification(
            "Ocorreu um erro",
            `Entre em contato com a equipe tecnica.`
          )
        );
      }
      return !error;
    } catch (error) {
      return false;
      console.error(error);
    }
  };

  function hasFormValueChanged(model: OnChangeModel): void {
    setFormState({ ...formState, [model.field]: { error: model.error, value: model.value } });
  }

  function hasFormValueChangedRichText(field: string, value: string): void {
    setFormState({ ...formState, [field]: { value, error: '' } });
  }

  function onQuestionSelect(cupon: IQuestion): void {
    dispatch(changeSelectedQuestion(cupon));
    dispatch(setModificationState(QuestionModificationStatus.None));
  }

  function cancelForm(): any {
    // dispatch(setModificationState(QuestionModificationStatus.None));
    return history.goBack()

  }

  function getDisabledClass(): string {
    let isError: boolean = isFormInvalid();
    return isError ? "disabled" : "";
  }

  function saveForm(formState: IQuestionFormState, saveFn: Function): void {
    const newQuestion = {
      ...questions,
      //  status: formState.status?.value||false,
      //  cuponTop: formState.cuponTop?.value||false,
    }
    console.log(newQuestion)
    //    if (cupon) {
    //    dispatch(saveFn(newCupon));
    //    _putCupon(cupon.id,{status:newCupon.status, cuponTop:newCupon.cuponTop} )

    //  }
  }

  return (
    <Fragment>
      <Fragment>
        <div className="">
          <div className="card shadow mb-4">
            <div className="card-header-detail py-3">
  <h6 className="m-0 card-header-detail-title">Nova Pergunta do Topico "{questionSubjectName}"</h6>
            </div>
            <div className="card-body">
              <form onSubmit={saveUser}>
                <div className="form-row">
                  <div className="form-group col-md-2">
                    <NumberInput id="input_order_number"
                      value={formState.orderNumber.value}
                      field="orderNumber"
                      onChange={hasFormValueChanged}
                      max={1000}
                      min={0}
                      label="Ordem" />
                  </div>
                  <div className="form-group col-md-2">
                    <CheckboxMaterialUi
                      id="input_status"
                      field="status"
                      value={formState.status.value}
                      label="Ativo"
                      onChange={hasFormValueChanged}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">

                    <RichText
                      label={"Pergunta"}
                      editorClassName={'textarea'}
                      className={'editor'}
                      field="name"
                      value={formState.name.value}
                      onChange={hasFormValueChangedRichText}
                    />

                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <RichText
                      label={"Resposta"}
                      editorClassName={'textarea'}
                      className={'editor'}
                      field="answer"
                      value={formState.answer.value}
                      onChange={hasFormValueChangedRichText}
                    />

                  </div>
                </div>

                <button type="button" className="btn btn-danger" onClick={() => cancelForm()}>Cancelar</button>
                <button type="submit" className={`btn btn-success left-margin ${getDisabledClass()}`}>Confirmar</button>
              </form>
            </div>
          </div>
        </div>
      </Fragment>
    </Fragment>
  );
};

export default QuestionsAdd;
