import { IQuestion, QuestionModificationStatus } from "../models/question.interface";
export const EDIT_QUESTION: string = "EDIT_QUESTION";
export const ADD_QUESTION: string = "ADD_QUESTION"; 
export const REMOVE_QUESTION: string = "REMOVE_QUESTION";
export const CHANGE_QUESTION_PENDING_EDIT: string = "CHANGE_QUESTION_PENDING_EDIT";
export const CLEAR_QUESTION_PENDING_EDIT: string = "CLEAR_QUESTION_PENDING_EDIT";
export const SET_MODIFICATION_STATE: string = "SET_MODIFICATION_STATE";
export const GET_QUESTION_STATE: string = "GET_QUESTION_STATE"

export function editQuestion(question: IQuestion): IEditQuestionActionType {
    return { type: EDIT_QUESTION, question: question };
}

export function addQuestion(question: IQuestion): IAddQuestionActionType {
    return { type: ADD_QUESTION, question: question };
}

export function removeQuestion(id: string): IRemoveQuestionActionType {
    return { type: REMOVE_QUESTION, id: id };
}

export function clearSelectedQuestion(): IClearSelectedQuestionActionType {
    return { type: CLEAR_QUESTION_PENDING_EDIT };
}

export function setModificationState(value: QuestionModificationStatus): ISetModificationStateActionType {
    return { type: SET_MODIFICATION_STATE, value: value };
}

export function changeSelectedQuestion(question: IQuestion): IChangeSelectedQuestionActionType {
    return { type: CHANGE_QUESTION_PENDING_EDIT, question: question };
}

export function getQuestionState(question: IQuestion): IGetQuestionState {
    return { type: GET_QUESTION_STATE, question: question };
}





interface IEditQuestionActionType { type: string, question: IQuestion };
interface IAddQuestionActionType { type: string, question: IQuestion };
interface IRemoveQuestionActionType { type: string, id: string };
interface ISetModificationStateActionType { type: string, value: QuestionModificationStatus };
interface IClearSelectedQuestionActionType { type: string };
interface IChangeSelectedQuestionActionType { type: string, question: IQuestion };
interface IGetQuestionState { type: string, question: IQuestion };
