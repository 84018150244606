import React, { Fragment, Dispatch } from "react";
import CommonQuestionsResaleList from "./CommonQuestionsResaleList";
import "./CommonQuestionsResale.css";
import { useDispatch } from "react-redux";
import {
  setModificationState,
  changeSelectedSubject,
} from "../../store/actions/subjects.action";
import {
  SubjectModificationStatus,
  ISubject,
} from "../../store/models/subject.interface";

const CommonQuestionsResale: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  console.log(CommonQuestionsResale)

  function onSubjectSelect(cupon: ISubject): void {
    dispatch(changeSelectedSubject(cupon));
    dispatch(setModificationState(SubjectModificationStatus.None));
  }

  return (
    <Fragment>
      <CommonQuestionsResaleList onSelect={onSubjectSelect} />
    </Fragment>
  );
};

export default CommonQuestionsResale;
