import { IQuestion } from "./question.interface";

export interface ISubjectResale {
    subjectId: number;
    title: string;
    question: IQuestion[];
    tableData?:any;
    status?: boolean;
}

export enum SubjectResaleModificationStatus {
    None = 0,
    Create = 1,
    Edit = 2
}