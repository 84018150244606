import { IQuestion } from "./question.interface";

export interface ISubject {
    subjectId: number;
    title: string;
    question: IQuestion[];
    tableData?:any;
    status?: boolean;
}

export enum SubjectModificationStatus {
    None = 0,
    Create = 1,
    Edit = 2
}