import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import kimLogo2 from '../../assets/kim-logo2.svg';
import "./LeftMenu.css";

const LeftMenu: React.FC = () => {

    let [leftMenuVisibility, setLeftMenuVisibility] = useState(false);

    function changeLeftMenuVisibility() {
        setLeftMenuVisibility(!leftMenuVisibility);
    }

    function getCollapseClass() {
        return (leftMenuVisibility) ? "" : "collapsed";
    }

    return (
        <Fragment>
            <div className="toggle-area">
                <button className="btn btn-primary toggle-button" onClick={() => changeLeftMenuVisibility()}>
                    <i className="fas fa-bolt"></i>
                </button>
            </div>

            <ul className={`navbar-nav bg-gradient-primary-green sidebar sidebar-dark accordion ${getCollapseClass()}`}
                id="collapseMenu">

                <a className="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
                <div className="sidebar-brand-text mx-3">
                <img className="top-menu-img" alt=""
          src={kimLogo2}/>  
                    
                    
                    </div>
                    {/* <div className="sidebar-brand-icon icon-orange">
                        <i className="fas fa-plus"></i>
                    </div> */}
                </a>

                <hr className="sidebar-divider my-0" />

                <li className="nav-item active">

                    <Link className="nav-link" to="Home">
                        <i className="fas fa-fw fa-tachometer-alt"></i>
                        <span>Dashboard</span>
                    </Link>
                </li>

                <hr className="sidebar-divider" />
                <div className="sidebar-heading">
                    Kim +
                </div>
{/* 
                <li className="nav-item">
                    <Link className="nav-link" to={`/products`}>
                        <i className="fas fa-fw fa-warehouse"></i>
                        <span>Products</span>
                    </Link>
                </li> */}

                <li className="nav-item">
                    <Link className="nav-link" to={`/offers`}>
                        <i className="fas fa-fw fa-bullhorn"></i>
                        <span>Ofertas</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className="nav-link" to={`/cupons`}>
                        <i className="fas fa-fw fa-tag"></i>
                        <span>Cupons</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className="nav-link" to={`/commonQuestions`}>
                        <i className="fas fa-fw fa-question-circle"></i>
                        <span>Perguntas frequentes</span>
                    </Link>
                </li>
                
                <hr className="sidebar-divider" />
                <div className="sidebar-heading">
                    Kim Revenda
                </div>

                <li className="nav-item">
                    <Link className="nav-link" to={`/commonQuestionsResale`}>
                        <i className="fas fa-fw fa-question-circle"></i>
                        <span>Perguntas frequentes</span>
                    </Link>
                </li>

                {/* <li className="nav-item">
                    <Link className="nav-link" to={`/orders`}>
                        <i className="fas fa-fw fa-dollar-sign"></i>
                        <span>Orders</span>
                    </Link>
                </li> */}

                {/* <hr className="sidebar-divider" />

                <div className="sidebar-heading">
                    Admin
                </div> */}


                {/* <li className="nav-item">
                    <Link className="nav-link" to={`/users`}>
                        <i className="fas fa-fw fa-user"></i>
                        <span>Users</span>
                    </Link>
                </li> */}

                <hr className="sidebar-divider d-none d-md-block" />
            </ul>
        </Fragment>
    );
};

export default LeftMenu;
