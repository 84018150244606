import { IQuestionResale, QuestionResaleModificationStatus } from "../models/questionResale.interface";
export const EDIT_QUESTIONRESALE: string = "EDIT_QUESTIONRESALE";
export const ADD_QUESTIONRESALE: string = "ADD_QUESTIONRESALE"; 
export const REMOVE_QUESTIONRESALE: string = "REMOVE_QUESTIONRESALE";
export const CHANGE_QUESTIONRESALE_PENDING_EDIT: string = "CHANGE_QUESTIONRESALE_PENDING_EDIT";
export const CLEAR_QUESTIONRESALE_PENDING_EDIT: string = "CLEAR_QUESTIONRESALE_PENDING_EDIT";
export const SET_MODIFICATION_STATE: string = "SET_MODIFICATION_STATE";
export const GET_QUESTIONRESALE_STATE: string = "GET_QUESTIONRESALE_STATE"

export function editQuestionResale(questionResale: IQuestionResale): IEditQuestionActionType {
    return { type: EDIT_QUESTIONRESALE, questionResale: questionResale };
}

export function addQuestionResale(questionResale: IQuestionResale): IAddQuestionActionType {
    return { type: ADD_QUESTIONRESALE, questionResale: questionResale };
}

export function removeQuestionResale(id: string): IRemoveQuestionActionType {
    return { type: REMOVE_QUESTIONRESALE, id: id };
}

export function clearSelectedQuestionResale(): IClearSelectedQuestionActionType {
    return { type: CLEAR_QUESTIONRESALE_PENDING_EDIT };
}

export function setModificationState(value: QuestionResaleModificationStatus): ISetModificationStateActionType {
    return { type: SET_MODIFICATION_STATE, value: value };
}

export function changeSelectedQuestionResale(questionResale: IQuestionResale): IChangeSelectedQuestionActionType {
    return { type: CHANGE_QUESTIONRESALE_PENDING_EDIT, questionResale: questionResale };
}

export function getQuestionResaleState(questionResale: IQuestionResale): IGetQuestionState {
    return { type: GET_QUESTIONRESALE_STATE, questionResale: questionResale };
}





interface IEditQuestionActionType { type: string, questionResale: IQuestionResale };
interface IAddQuestionActionType { type: string, questionResale: IQuestionResale };
interface IRemoveQuestionActionType { type: string, id: string };
interface ISetModificationStateActionType { type: string, value: QuestionResaleModificationStatus };
interface IClearSelectedQuestionActionType { type: string };
interface IChangeSelectedQuestionActionType { type: string, questionResale: IQuestionResale };
interface IGetQuestionState { type: string, questionResale: IQuestionResale };
