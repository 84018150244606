
import { ISubjectState, IActionBase } from "../models/root.interface";
import {
    EDIT_SUBJECT, ADD_SUBJECT, REMOVE_SUBJECT, CHANGE_SUBJECT_PENDING_EDIT, CLEAR_SUBJECT_PENDING_EDIT, SET_MODIFICATION_STATE,
    GET_SUBJECT_STATE
} from "../actions/subjects.action";
import { ISubject } from "../models/subject.interface";


const initialState: ISubjectState = {
    modificationState: 0,
    selectedSubject: null,
    subjects: []
};

function subjectsReducer(state: ISubjectState = initialState, action: IActionBase): ISubjectState {

    switch (action.type) {
        case EDIT_SUBJECT: {
            const foundIndex: number = state.subjects.findIndex(pr => pr.subjectId === action.subject.subjectId);
            let subjects: ISubject[] = state.subjects;
            subjects[foundIndex] = action.subject;
            return { ...state, subjects: subjects };
        }
        case ADD_SUBJECT: {
            let maxId: number = Math.max.apply(Math, state.subjects.map((o) => { return o.subjectId; }));
            if(maxId === -Infinity) { maxId = 0; }
            return {...state, subjects: [...state.subjects, {...action.subject, subjectId: maxId + 1, question: [], tableData: {id: maxId + 1}}]};
        }
        case REMOVE_SUBJECT: {
            return { ...state, subjects: state.subjects.filter(pr => pr.subjectId !== Number(action.id)) };
        }
        case CHANGE_SUBJECT_PENDING_EDIT: {
            return { ...state, selectedSubject: action.subject };
        }
        case CLEAR_SUBJECT_PENDING_EDIT: {
            return { ...state, selectedSubject: null };
        }
        case SET_MODIFICATION_STATE: {
            return { ...state, modificationState: action.value };
        }
        case GET_SUBJECT_STATE: {
            let subjects: ISubject[] = action.subject;
            return { ...state, subjects: subjects };
        }
        default:
            return state;
    }
}


export default subjectsReducer;