export interface IProduct {
    id: number;
    description: string;
    category: string;
    link:string;
    image:string;
    price: number;
    priceOffer: number;
    favorite:boolean;
    amount:number;
}

export enum ProductModificationStatus {
    None = 0,
    Create = 1,
    Edit = 2
}