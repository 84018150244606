/* eslint-disable react/jsx-no-target-blank */
import React, { Dispatch, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory,useLocation } from 'react-router-dom';
import { IStateType, IQuestionResaleState } from "../../store/models/root.interface";
import {
  setModificationState,
  editQuestionResale,
  addQuestionResale,
  removeQuestionResale,
  getQuestionResaleState,
} from "../../store/actions/questionResales.actions";
import {
  QuestionResaleModificationStatus,
  IQuestionResale
} from "../../store/models/questionResale.interface";

import { addNotification } from "../../store/actions/notifications.action";

import services from "../../requester/services";
import requester from "../../requester/requester";
import {language} from "../../languages"

import MaterialTable from "material-table";
import MaterialTableIcons from "../../common/components/MaterialTableIcons";

import AddBox from '@material-ui/icons/AddBox';
import Edit from '@material-ui/icons/Edit';
import Link from "@material-ui/icons/Link";
import Refresh from "@material-ui/icons/Refresh";
import List from '@material-ui/icons/List';
import CheckBox from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import Star from '@material-ui/icons/Star';
import StarOutline from '@material-ui/icons/StarBorderOutlined';
// import Clear from '@material-ui/icons/Clear';
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { MuiThemeProvider } from "@material-ui/core/styles";


export type subjectListProps = {
  onSelect?: (questions: IQuestionResale) => void;
  pageId: number;
  children?: React.ReactNode;
};

function QuestionResalesList(props: subjectListProps): JSX.Element {
  const questionResales: IQuestionResaleState = useSelector((state: IStateType) => state.questionResales);
  const dispatch: Dispatch<any> = useDispatch();
  const location = useLocation();
  const token = useSelector((state: IStateType) => state.account.token);
  let history = useHistory();
  const { questionSubjectName } = location.state as any || "";
  
  const questionLanguage = language.question;

  const list = Array.from(questionResales.questionResales).map(d => {
    if(d.tableData){
    d.tableData.checked = false
  }
  return d
  });


  const editQuestionResaleList = async (subject: IQuestionResale, id: string, array: any = []) => {
    console.log(subject);
    const newQuestion = {
      ...subject,

    };
    if (subject) {
      (await _putQuestionResale(id, {
        ...subject
      })) && dispatch(editQuestionResale(newQuestion));
    }
  };

  const addQuestionResaleList = async (item: IQuestionResale) => {
    console.log(item);
    const newQuestion = {
      ...item,
      orderNumber:Number(item.orderNumber),
      questionSubjectId:Number(props.pageId)
    };
    if (item) {
      (await _postQuestionResale( {
        ...newQuestion
      })) && dispatch(addQuestionResale(newQuestion));
    }
  };

  const removeQuestionList = async (id: string) => {

    if (id) {
      (await _deleteQuestionResale(id)) && dispatch(removeQuestionResale(id));
    }
  };

  const _getQuestionResaleList = async (id: number) => {
    try {
      const service = {
        ...services.getQuestionResale,
        endpoint: services.getQuestionResale.endpoint.replace("{{id}}", id),
      };
      const account = { token: token };
      const [error, response]: any = await requester(account, service);
      dispatch(getQuestionResaleState(response));
    } catch (error) {
      console.error(error);
    }
  };

  const _putQuestionResale = async (id: string, body: object) => {
    try {
      const service = {
        ...services.putQuestionResale,
        endpoint: services.putQuestionResale.endpoint.replace("{{id}}", id),
      };
      const account = { token: token };
      const options = {
        data: body,
      };
      const [error, response]: any = await requester(account, service, options);
      if (!error) {
        dispatch(setModificationState(QuestionResaleModificationStatus.Edit));
      } else {
        dispatch(
          addNotification(
            "Ocorreu um erro",
            `Entre em contato com a equipe tecnica.`
          )
        );
      }
      return !error;
    } catch (error) {
      return false;
      console.error(error);
    }
  };

  const _postQuestionResale = async (body: object) => {
    try {
      const service = services.postQuestionResale
      const account = { token: token };
      const options = {
        data: body,
      };
      const [error, response]: any = await requester(account, service, options);
      if (!error) {
        dispatch(setModificationState(QuestionResaleModificationStatus.Edit));
      } else {
        dispatch(
          addNotification(
            "Ocorreu um erro",
            `Entre em contato com a equipe tecnica.`
          )
        );
      }
      return !error;
    } catch (error) {
      return false;
      console.error(error);
    }
  };

  const _deleteQuestionResale = async (id: string) => {
    try {
      const service = {
        ...services.deleteQuestionResale,
        endpoint: services.deleteQuestionResale.endpoint.replace("{{id}}", id),
      };
      const account = { token: token };
      
      const [error, response]: any = await requester(account, service);
      if (!error) {
        dispatch(setModificationState(QuestionResaleModificationStatus.Edit));
      } else {
        dispatch(
          addNotification(
            "Ocorreu um erro",
            `Entre em contato com a equipe tecnica.`
          )
        );
      }
      return !error;
    } catch (error) {
      return false;
      console.error(error);
    }
  };

  useEffect(() => {
    _getQuestionResaleList(props.pageId);
  }, []);

  const theme = createMuiTheme({
    overrides: {
      MuiCheckbox: {
        root: {
            padding: '5px',
        },
        colorSecondary: {
          // color: '#E74040',
            '&$checked': {
                // color: 'primary',
            },
        },
    },
    },
    props: {
      MuiCheckbox: {
        color: "primary",
        // style: {backgroundColor:'#4caf50'}
      },
      MuiRadio: {
        color: "primary",
      },
    },
    palette: {
      primary: {
        main: '#4caf50',
      },
      secondary: {
        main: '#432184' ,
      }
  
    },

  });

  return (
    <MuiThemeProvider theme={theme}>
    <MaterialTable
      icons={MaterialTableIcons}
      columns={[
        // { title: questionLanguage.id, field: "id", type: "numeric", editable: "never" },
        { title: questionLanguage.subjectId, field: "orderNumber", type: "numeric" },
        { title: questionLanguage.name, field: "name" },
        { title: questionLanguage.answer, field: "answer" },
        {
          title: questionLanguage.status,
          field: "status",
          type: "boolean",
          editable: "onUpdate",
          // cellStyle:(data: any, rowData: any) => console.log(),
          render: (rowData) => (
            rowData.status? <MaterialTableIcons.Check /> : <MaterialTableIcons.ThirdStateCheck  /> 
          ),
        },
      ]}
      data={list || []}
      options={{
        filtering: true,
        columnsButton: true,
        search: false,
        selection: true,
        rowStyle: data => {
          // console.log(data)
        return data.tableData.id % 2
          ? { background: "#F1F0FF" }
          : { background: "white" }
        }
        // headerStyle: {backgroundColor: "rgba(67, 33, 132, 0.22)"}
      }}
      title={questionLanguage.list}
      localization={questionLanguage.localization}
      editable={{
        // onRowUpdate: async (newData, oldData) => {
        //   editQuestionList(newData, newData.id.toString())
          
        // },
        // onRowAdd: async (newData) => addQuestionList({...newData}),
        // onRowDelete: async (oldData) => removeQuestionList(oldData.id.toString()),
      }}
      actions={[
        {
          icon: AddBox,
          isFreeAction: true,
          tooltip: questionLanguage.addTooltip,
          position: 'row',
          onClick: (event, data) => history.push(`${props.pageId}/add`,{questionSubjectName}),
        },
        {
          icon: Edit,
          tooltip: 'Editar',
          position: 'row',
          onClick: (event, data:any ) => {
            history.push(`${props.pageId}/edit/${data.id}`,{...data})
          },
        }
      ]}
    />
    </MuiThemeProvider>
  );
}

export default QuestionResalesList;
