import { ICupon, CuponModificationStatus } from "../models/cupon.interface";
export const EDIT_CUPON: string = "EDIT_CUPON";
export const CHANGE_CUPON_PENDING_EDIT: string = "CHANGE_CUPON_PENDING_EDIT";
export const CLEAR_CUPON_PENDING_EDIT: string = "CLEAR_CUPON_PENDING_EDIT";
export const SET_MODIFICATION_STATE: string = "SET_MODIFICATION_STATE";
export const GET_CUPON_STATE: string = "GET_CUPON_STATE"

export function editCupon(cupon: ICupon): IEditCuponActionType {
    return { type: EDIT_CUPON, cupon: cupon };
}

export function clearSelectedCupon(): IClearSelectedCuponActionType {
    return { type: CLEAR_CUPON_PENDING_EDIT };
}

export function setModificationState(value: CuponModificationStatus): ISetModificationStateActionType {
    return { type: SET_MODIFICATION_STATE, value: value };
}

export function changeSelectedCupon(cupon: ICupon): IChangeSelectedCuponActionType {
    return { type: CHANGE_CUPON_PENDING_EDIT, cupon: cupon };
}

export function getCuponState(cupon: ICupon): IGetCuponState {
    return { type: GET_CUPON_STATE, cupon: cupon };
}





interface IEditCuponActionType { type: string, cupon: ICupon };
interface ISetModificationStateActionType { type: string, value: CuponModificationStatus };
interface IClearSelectedCuponActionType { type: string };
interface IChangeSelectedCuponActionType { type: string, cupon: ICupon };
interface IGetCuponState { type: string, cupon: ICupon };
