import React, { Fragment, Dispatch } from "react";
import CuponList from "./CuponsList";
import "./Cupons.css";
import { useDispatch } from "react-redux";
import {
  setModificationState,
  changeSelectedCupon,
} from "../../store/actions/cupons.actions";
import {
  CuponModificationStatus,
  ICupon,
} from "../../store/models/cupon.interface";

const Cupons: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();

  function onCuponsSelect(cupon: ICupon): void {
    dispatch(changeSelectedCupon(cupon));
    dispatch(setModificationState(CuponModificationStatus.None));
  }

  return (
    <Fragment>
      <CuponList onSelect={onCuponsSelect} />
    </Fragment>
  );
};

export default Cupons;
