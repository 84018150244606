
import { IQuestionState, IActionBase } from "../models/root.interface";
import {
    EDIT_QUESTION, ADD_QUESTION, REMOVE_QUESTION, CHANGE_QUESTION_PENDING_EDIT, CLEAR_QUESTION_PENDING_EDIT, SET_MODIFICATION_STATE,
    GET_QUESTION_STATE
} from "../actions/questions.actions";
import { IQuestion } from "../models/question.interface";


const initialState: IQuestionState = {
    modificationState: 0,
    selectedQuestion: null,
    questions: []
};

function questionsReducer(state: IQuestionState = initialState, action: IActionBase): IQuestionState {

    switch (action.type) {
        case EDIT_QUESTION: {
            const foundIndex: number = state.questions.findIndex(pr => pr.id === action.question.id);
            let questions: IQuestion[] = state.questions;
            questions[foundIndex] = action.question;
            return { ...state, questions: questions };
        }
        case ADD_QUESTION: {
            let maxId: number = Math.max.apply(Math, state.questions.map((o) => { return o.id; }));
            if(maxId === -Infinity) { maxId = 0; }
            return {...state, questions: [...state.questions, {...action.question, id: maxId + 1, tableData: {id: maxId + 1}}]};
        }
        case REMOVE_QUESTION: {
            return { ...state, questions: state.questions.filter(pr => pr.id !== Number(action.id)) };
        }
        case CHANGE_QUESTION_PENDING_EDIT: {
            return { ...state, selectedQuestion: action.question };
        }
        case CLEAR_QUESTION_PENDING_EDIT: {
            return { ...state, selectedQuestion: null };
        }
        case SET_MODIFICATION_STATE: {
            return { ...state, modificationState: action.value };
        }
        case GET_QUESTION_STATE: {
            let questions: IQuestion[] = action.question;
            return { ...state, questions: questions };
        }
        default:
            return state;
    }
}


export default questionsReducer;