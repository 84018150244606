
import { IOfferState, IActionBase } from "../models/root.interface";
import {
    EDIT_OFFER, CHANGE_OFFER_PENDING_EDIT, CLEAR_OFFER_PENDING_EDIT, SET_MODIFICATION_STATE,
    GET_OFFER_STATE
} from "../actions/offers.actions";
import { IOffer } from "../models/offer.interface";


const initialState: IOfferState = {
    modificationState: 0,
    selectedOffer: null,
    offers: []
};

function offersReducer(state: IOfferState = initialState, action: IActionBase): IOfferState {
    
    switch (action.type) {
        case EDIT_OFFER: {
           const foundIndex: number = state.offers.findIndex(pr => pr.id === action.offer.id);
           let offers: IOffer[] = state.offers;
           offers[foundIndex] = action.offer;
           return { ...state, offers: offers };
        }
        case CHANGE_OFFER_PENDING_EDIT: {
           return { ...state, selectedOffer: action.offer };
        }
        case CLEAR_OFFER_PENDING_EDIT: {
           return { ...state, selectedOffer: null };
        }
        case SET_MODIFICATION_STATE: {
           return { ...state, modificationState: action.value };
        }
        case GET_OFFER_STATE: {
            let offers: IOffer[] = action.offer;
            return { ...state, offers: offers };
         }
        default:
            return state;
    }
}


export default offersReducer;