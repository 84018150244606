/* eslint-disable react/jsx-no-target-blank */
import React, { Dispatch, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { IStateType, ISubjectState } from "../../store/models/root.interface";
import {
  setModificationState,
  editSubject,
  addSubject,
  removeSubject,
  getSubjectState,
} from "../../store/actions/subjects.action";
import {
  ISubject,
  SubjectModificationStatus,
} from "../../store/models/subject.interface";

import { addNotification } from "../../store/actions/notifications.action";

import services from "../../requester/services";
import requester from "../../requester/requester";
import {language} from "../../languages"

import MaterialTable from "material-table";
import MaterialTableIcons from "../../common/components/MaterialTableIcons";

import Link from "@material-ui/icons/Link";
import Refresh from "@material-ui/icons/Refresh";
import List from '@material-ui/icons/List';
import CheckBox from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import Star from '@material-ui/icons/Star';
import StarOutline from '@material-ui/icons/StarBorderOutlined';
// import Clear from '@material-ui/icons/Clear';
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { MuiThemeProvider } from "@material-ui/core/styles";


export type subjectListProps = {
  onSelect?: (subjects: ISubject) => void;
  children?: React.ReactNode;
};

function CommonQuestionsList(props: subjectListProps): JSX.Element {
  const subjects: ISubjectState = useSelector((state: IStateType) => state.subjects);
  const dispatch: Dispatch<any> = useDispatch();
  const token = useSelector((state: IStateType) => state.account.token);
  const history = useHistory();
  
  const commonQuestionLanguage = language.commonQuestion;

  const list = Array.from(subjects.subjects).map(d => {
    if(d.tableData){
    d.tableData.checked = false
  }
  return d
  });
  
  // const listStoreCategoryNameObject = Object.assign(
  //   {},
  //   ...list.map((item) => ({ [item.storeCategoryName]: item.storeCategoryName }))
  // );
  // const listStoreNameObject = Object.assign(
  //   {},
  //   ...list.map((item) => ({ [item.storeName]: item.storeName }))
  // );

  // const listStoreCategoryName = Object.keys(listStoreCategoryNameObject)
  //   .sort()
  //   .reduce(function (acc:any, key:any) { 
  //       acc[key] = listStoreCategoryNameObject[key];
  //       return acc;
  //   }, {});

  //   const listStoreName = Object.keys(listStoreNameObject)
  //   .sort()
  //   .reduce(function (acc:any, key:any) { 
  //       acc[key] = listStoreNameObject[key];
  //       return acc;
  //   }, {});

  

  const editSubjectList = async (subject: ISubject, id: string, array: any = []) => {
    console.log(subject);
    const newSubject = {
      ...subject,

    };
    if (subject) {
      (await _putSubject(id, {
        ...subject
      })) && dispatch(editSubject(newSubject));
    }
  };

  const addSubjectList = async (subject: ISubject) => {
    console.log(subject);
    const newSubject = {
      ...subject
    };
    if (subject) {
      (await _postSubject( {
        ...subject
      })) && dispatch(addSubject(newSubject));
    }
  };

  const removeSubjectList = async (id: string) => {

    if (id) {
      (await _deleteSubject(id)) && dispatch(removeSubject(id));
    }
  };

  const _getQuestionsList = async () => {
    try {
      const { commonQuestionsList: service } = services;
      const account = { token: token };
      const [error, response]: any = await requester(account, service);
      dispatch(getSubjectState(response));
    } catch (error) {
      console.error(error);
    }
  };

  const _putSubject = async (id: string, body: object) => {
    try {
      const service = {
        ...services.putCommonQuestions,
        endpoint: services.putCommonQuestions.endpoint.replace("{{id}}", id),
      };
      const account = { token: token };
      const options = {
        data: body,
      };
      const [error, response]: any = await requester(account, service, options);
      if (!error) {
        dispatch(setModificationState(SubjectModificationStatus.Edit));
      } else {
        dispatch(
          addNotification(
            "Ocorreu um erro",
            `Entre em contato com a equipe tecnica.`
          )
        );
      }
      return !error;
    } catch (error) {
      return false;
      console.error(error);
    }
  };

  const _postSubject = async (body: object) => {
    try {
      const service = services.postCommonQuestions
      const account = { token: token };
      const options = {
        data: body,
      };
      const [error, response]: any = await requester(account, service, options);
      if (!error) {
        dispatch(setModificationState(SubjectModificationStatus.Edit));
      } else {
        dispatch(
          addNotification(
            "Ocorreu um erro",
            `Entre em contato com a equipe tecnica.`
          )
        );
      }
      return !error;
    } catch (error) {
      return false;
      console.error(error);
    }
  };

  const _deleteSubject = async (id: string) => {
    try {
      const service = {
        ...services.deleteCommonQuestions,
        endpoint: services.deleteCommonQuestions.endpoint.replace("{{id}}", id),
      };
      const account = { token: token };
      
      const [error, response]: any = await requester(account, service);
      if (!error) {
        dispatch(setModificationState(SubjectModificationStatus.Edit));
      } else {
        dispatch(
          addNotification(
            "Ocorreu um erro",
            `Entre em contato com a equipe tecnica.`
          )
        );
      }
      return !error;
    } catch (error) {
      return false;
      console.error(error);
    }
  };

  useEffect(() => {
    _getQuestionsList();
  }, []);

  const theme = createMuiTheme({
    overrides: {
      MuiCheckbox: {
        root: {
            padding: '5px',
        },
        colorSecondary: {
          // color: '#E74040',
            '&$checked': {
                // color: 'primary',
            },
        },
    },
    },
    props: {
      MuiCheckbox: {
        color: "primary",
        // style: {backgroundColor:'#4caf50'}
      },
      MuiRadio: {
        color: "primary",
      },
    },
    palette: {
      primary: {
        main: '#4caf50',
      },
      secondary: {
        main: '#432184' ,
      }
  
    },

  });

  return (
    <MuiThemeProvider theme={theme}>
    <MaterialTable
      icons={MaterialTableIcons}
      columns={[
        { title: commonQuestionLanguage.subjectId, field: "subjectId", type: "numeric", editable: "never" },
        { title: commonQuestionLanguage.title, field: "title" },
        {
          title: commonQuestionLanguage.status,
          field: "status",
          type: "boolean",
          editable: "always",
          // cellStyle:(data: any, rowData: any) => console.log(),
          render: (rowData) => (
            rowData?.status? <MaterialTableIcons.Check /> : <MaterialTableIcons.ThirdStateCheck  /> 
          ),
        },
      ]}
      data={list || []}
      options={{
        filtering: true,
        columnsButton: true,
        search: false,
        selection: true,
        rowStyle: data => {
          // console.log(data)
        return data.tableData.id % 2
          ? { background: "#F1F0FF" }
          : { background: "white" }
        }
        // headerStyle: {backgroundColor: "rgba(67, 33, 132, 0.22)"}
      }}
      title={commonQuestionLanguage.list}
      localization={commonQuestionLanguage.localization}
      editable={{
        onRowUpdate: async (newData, oldData) => editSubjectList(newData, newData.subjectId.toString()),
        onRowAdd: async (newData) => addSubjectList(newData),
        // onRowDelete: async (oldData) => removeSubjectList(oldData.subjectId.toString()),
      }}
      actions={[
        {
          icon: List,
          tooltip: commonQuestionLanguage.listIcon,
          position: 'row',
          onClick: (event, data:any ) => {
            history.push(`questions/${data.subjectId}`,{questionSubjectName:data.title})
          },
        }
      ]}
    />
    </MuiThemeProvider>
  );
}

export default CommonQuestionsList;
