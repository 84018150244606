import React, { Fragment, Dispatch } from "react";
import {
  useParams
} from "react-router-dom";
import QuestionResalesList from "./QuestionResalesList";
import "./QuestionResales.css";
import { useDispatch } from "react-redux";
import {
  setModificationState,
  changeSelectedQuestion,
} from "../../store/actions/questions.actions";
import {
  QuestionModificationStatus,
  IQuestion,
} from "../../store/models/question.interface";

const QuestionResales: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  let { id } = useParams();

  function onQuestionSelect(cupon: IQuestion): void {
    dispatch(changeSelectedQuestion(cupon));
    dispatch(setModificationState(QuestionModificationStatus.None));
  }

  return (
    <Fragment>
      <QuestionResalesList onSelect={onQuestionSelect} pageId={id} />
    </Fragment>
  );
};

export default QuestionResales;
