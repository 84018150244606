export interface IQuestion {
    id : number;
    questionSubjectId: number;
    questionSubjectName?: string;
    orderNumber: number;
    likes?: number;
    deslikes?: number;
    name: string;
    answer: string;
    tableData?:any;
    status?: boolean;
}

export enum QuestionModificationStatus {
    None = 0,
    Create = 1,
    Edit = 2
}