import { IOffer, OfferModificationStatus } from "../models/offer.interface";
export const EDIT_OFFER: string = "EDIT_OFFER";
export const CHANGE_OFFER_PENDING_EDIT: string = "CHANGE_OFFER_PENDING_EDIT";
export const CLEAR_OFFER_PENDING_EDIT: string = "CLEAR_OFFER_PENDING_EDIT";
export const SET_MODIFICATION_STATE: string = "SET_MODIFICATION_STATE";
export const GET_OFFER_STATE: string = "GET_OFFER_STATE"

export function editOffer(offer: IOffer): IEditOfferActionType {
    return { type: EDIT_OFFER, offer: offer };
}

export function clearSelectedOffer(): IClearSelectedOfferActionType {
    return { type: CLEAR_OFFER_PENDING_EDIT };
}

export function setModificationState(value: OfferModificationStatus): ISetModificationStateActionType {
    return { type: SET_MODIFICATION_STATE, value: value };
}

export function changeSelectedOffer(offer: IOffer): IChangeSelectedOfferActionType {
    return { type: CHANGE_OFFER_PENDING_EDIT, offer: offer };
}

export function getOfferState(offer: IOffer): IGetOfferState {
    return { type: GET_OFFER_STATE, offer: offer };
}





interface IEditOfferActionType { type: string, offer: IOffer };
interface ISetModificationStateActionType { type: string, value: OfferModificationStatus };
interface IClearSelectedOfferActionType { type: string };
interface IChangeSelectedOfferActionType { type: string, offer: IOffer };
interface IGetOfferState { type: string, offer: IOffer };
